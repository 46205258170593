.izy_rehetra {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}

.logoCDV {
  position: absolute;
  left: 230px;
  top: 90px;
  width: 500px;
  height: auto;
  cursor: pointer;
  z-index: 10;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
.btn_12 {
  position: absolute;
  left: 910px;
  top: 1285px;
  width: 506px;
  min-height: 73px;
  background-color: #1b8ece;
  color: white;
  font-size: 1.5vw;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  border-radius: 15px !important;
  z-index: 10 !important;
}

.btn_33 {
  position: absolute;
  left: 59px;
  top: 2881px;
  width: 506px;
  min-height: 73px;
  background-color: #1b8ece;
  color: white;
  font-size: 1.5vw;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  border-radius: 15px !important;
  z-index: 10 !important;
}

.btn_53 {
  position: absolute;
  left: 990px;
  top: 4050px;
  width: 506px;
  min-height: 73px;
  background-color: #1b8ece;
  color: white;
  font-size: 1.5vw;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  border-radius: 15px !important;
  z-index: 10 !important;
}

.btn_69 {
  position: absolute;
  left: 701px;
  top: 4950px;
  width: 506px;
  min-height: 73px;
  background-color: #1b8ece;
  color: white;
  font-size: 1.5vw;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  border-radius: 15px !important;
  z-index: 10 !important;
}

.btn_89 {
  position: absolute;
  left: 126px;
  top: 6334px;
  width: 506px;
  min-height: 73px;
  background-color: #1b8ece;
  color: white;
  font-size: 1.5vw;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  border-radius: 15px !important;
  z-index: 10 !important;
}

.btn_100 {
  position: absolute;
  text-align: center;
  left: 526px;
  top: 7970px;
  width: 506px;
  min-height: 73px;
  background-color: #1b8ece;
  color: white;
  font-size: 1.5vw;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  border-radius: 15px !important;
  z-index: 10 !important;
}

.btn_02 {
  position: absolute;
  left: 220px;
  top: 232px;
  width: 506px;
  min-height: 73px;
  background-color: #1b8ece;
  color: white;
  font-size: 1.5vw;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  border-radius: 15px !important;
  z-index: 10 !important;
}

@media (min-width: 1400px) {
  .btn_02,
  .btn_100,
  .btn_89,
  .btn_69,
  .btn_53,
  .btn_33,
  .btn_12 {
    width: 500px;
    font-size: 1.3em;
  }
}

.logoCDV:hover {
  opacity: 0.9;
}

.tuile {
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  cursor: pointer;
  z-index: 1000;
  transition: all 0.5s ease;
}

.tuile:hover {
  transform: scale(1.05);
}

#tsparticles {
  width: 100%;
  height: 100%;
  position: fixed;
  margin: 0;
  top: 0;
  left: 0;
}

#tsparticles canvas {
  display: block;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  -webkit-transition: opacity 0.8s ease, -webkit-transform 1.4s ease;
  transition: opacity 0.8s ease, transform 1.4s ease;
}

canvas {
  display: block;
  position: fixed;
  z-index: -1;
}

div.Table_01 {
  position: relative;
  left: 0px;
  top: 0px;
  width: 1519px;
  height: 8501px;
}

div.bRes-01 {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 1519px;
  height: 388px;
}

div.bRes-02 {
  position: absolute;
  left: 0px;
  top: 388px;
  width: 949px;
  height: 513px;
}

div.bRes-03 .part1 {
  position: absolute;
  left: 949px;
  top: 388px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/bRes_07.jpg);
}

/*.part {
  position:absolute;
  width:444px;
  height:284px;
  background-image: url(/src/images/bRes_07.jpg);
}

.current {
  position:absolute;
  width:444px;
  height:284px;
  background-image: url(/src/images/green.png);
  background-color: #0077b6;
}*/

div.bRes-03 .current1 {
  position: absolute;
  left: 949px;
  top: 388px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/green.png);
  background-color: #0077b6;
}

div.bRes-03 .done1 {
  position: absolute;
  left: 949px;
  top: 388px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/road-map_box-1.jpg);
}

div.bRes-04 {
  position: absolute;
  left: 1393px;
  top: 388px;
  width: 126px;
  height: 2517px;
}

div.bRes-05 {
  position: absolute;
  left: 949px;
  top: 672px;
  width: 444px;
  height: 229px;
}

div.bRes-06 {
  position: absolute;
  left: 0px;
  top: 901px;
  width: 242px;
  height: 400px;
}

div.bRes-07 .part3 {
  position: absolute;
  left: 242px;
  top: 901px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/bRes_07.jpg);
}

div.bRes-07 .current3 {
  position: absolute;
  left: 242px;
  top: 901px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/green.png);
  background-color: #0077b6;
}

div.bRes-07 .done3 {
  position: absolute;
  left: 242px;
  top: 901px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/road-map_box-2.jpg);
}

div.bRes-08 {
  position: absolute;
  left: 686px;
  top: 901px;
  width: 257px;
  height: 617px;
}

div.bRes-09 .part2 {
  position: absolute;
  left: 943px;
  top: 901px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/bRes_07.jpg);
}

div.bRes-09 .current2 {
  position: absolute;
  left: 943px;
  top: 901px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/green.png);
  background-color: #0077b6;
}

div.bRes-09 .done2 {
  position: absolute;
  left: 943px;
  top: 901px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/road-map_box-3.jpg);
}

div.bRes-10 {
  position: absolute;
  left: 1387px;
  top: 901px;
  width: 6px;
  height: 2004px;
}

div.bRes-11 {
  position: absolute;
  left: 242px;
  top: 1185px;
  width: 444px;
  height: 116px;
}

div.bRes-12 {
  position: absolute;
  left: 943px;
  top: 1185px;
  width: 444px;
  height: 333px;
}

div.bRes-13 {
  position: absolute;
  left: 0px;
  top: 1301px;
  width: 148px;
  height: 1076px;
}

div.bRes-14 .part4 {
  position: absolute;
  left: 148px;
  top: 1301px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/bRes_07.jpg);
}

div.bRes-14 .current4 {
  position: absolute;
  left: 148px;
  top: 1301px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/green.png);
  background-color: #0077b6;
}
div.bRes-14 .done4 {
  position: absolute;
  left: 148px;
  top: 1301px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/road-map_box-4.jpg);
}

div.bRes-15 {
  position: absolute;
  left: 592px;
  top: 1301px;
  width: 94px;
  height: 699px;
}

div.bRes-16 {
  position: absolute;
  left: 686px;
  top: 1518px;
  width: 249px;
  height: 1071px;
}

div.bRes-17 .part5 {
  position: absolute;
  left: 935px;
  top: 1518px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/bRes_07.jpg);
}

div.bRes-17 .current5 {
  position: absolute;
  left: 935px;
  top: 1518px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/green.png);
  background-color: #0077b6;
}
div.bRes-17 .done5 {
  position: absolute;
  left: 935px;
  top: 1518px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/road-map_box-5.jpg);
}

div.bRes-18 {
  position: absolute;
  left: 1379px;
  top: 1518px;
  width: 8px;
  height: 1387px;
}

div.bRes-19 {
  position: absolute;
  left: 148px;
  top: 1585px;
  width: 444px;
  height: 415px;
}

div.bRes-20 {
  position: absolute;
  left: 935px;
  top: 1802px;
  width: 444px;
  height: 198px;
}

div.bRes-21 {
  position: absolute;
  left: 148px;
  top: 2000px;
  width: 75px;
  height: 377px;
}

div.bRes-22 .part7 {
  position: absolute;
  left: 223px;
  top: 2000px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/bRes_07.jpg);
}

div.bRes-22 .current7 {
  position: absolute;
  left: 223px;
  top: 2000px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/green.png);
  background-color: #0077b6;
}

div.bRes-22 .done7 {
  position: absolute;
  left: 223px;
  top: 2000px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/road-map_box-6.jpg);
}

div.bRes-23 {
  position: absolute;
  left: 667px;
  top: 2000px;
  width: 19px;
  height: 589px;
}

div.bRes-24 .part6 {
  position: absolute;
  left: 935px;
  top: 2000px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/bRes_07.jpg);
}

div.bRes-24 .done6 {
  position: absolute;
  left: 935px;
  top: 2000px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/road-map_box-7.jpg);
}

div.bRes-25 {
  position: absolute;
  left: 223px;
  top: 2284px;
  width: 444px;
  height: 93px;
}

div.bRes-26 {
  position: absolute;
  left: 935px;
  top: 2284px;
  width: 444px;
  height: 305px;
}

div.bRes-27 {
  position: absolute;
  left: 0px;
  top: 2377px;
  width: 59px;
  height: 5384px;
}

div.bRes-28 .part8 {
  position: absolute;
  left: 59px;
  top: 2377px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/bRes_07.jpg);
}

div.bRes-28 .current8 {
  position: absolute;
  left: 59px;
  top: 2377px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/green.png);
  background-color: #0077b6;
}

div.bRes-28 .done8 {
  position: absolute;
  left: 59px;
  top: 2377px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/road-map_box-8.jpg);
}
div.bRes-29 {
  position: absolute;
  left: 503px;
  top: 2377px;
  width: 164px;
  height: 212px;
}

div.bRes-30 {
  position: absolute;
  left: 503px;
  top: 2589px;
  width: 93px;
  height: 411px;
}

div.bRes-31 .part9 {
  position: absolute;
  left: 596px;
  top: 2589px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/bRes_07.jpg);
}

div.bRes-31 .current9 {
  position: absolute;
  left: 596px;
  top: 2589px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/green.png);
  background-color: #0077b6;
}

div.bRes-31 .done9 {
  position: absolute;
  left: 596px;
  top: 2589px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/road-map_box-9.jpg);
}

div.bRes-32 {
  position: absolute;
  left: 1040px;
  top: 2589px;
  width: 339px;
  height: 316px;
}

div.bRes-33 {
  position: absolute;
  left: 59px;
  top: 2661px;
  width: 444px;
  height: 339px;
}

div.bRes-34 {
  position: absolute;
  left: 596px;
  top: 2873px;
  width: 444px;
  height: 32px;
}

div.bRes-35 {
  position: absolute;
  left: 596px;
  top: 2905px;
  width: 437px;
  height: 95px;
}

div.bRes-36 .part10 {
  position: absolute;
  left: 1033px;
  top: 2905px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/bRes_07.jpg);
}

div.bRes-36 .current10 {
  position: absolute;
  left: 1033px;
  top: 2905px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/green.png);
  background-color: #0077b6;
}

div.bRes-36 .done10 {
  position: absolute;
  left: 1033px;
  top: 2905px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/road-map_box-10.jpg);
}

div.bRes-37 {
  position: absolute;
  left: 1477px;
  top: 2905px;
  width: 42px;
  height: 4856px;
}

div.bRes-38 {
  position: absolute;
  left: 59px;
  top: 3000px;
  width: 337px;
  height: 391px;
}

div.bRes-39 .part11 {
  position: absolute;
  left: 396px;
  top: 3000px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/bRes_07.jpg);
}

div.bRes-39 .current11 {
  position: absolute;
  left: 396px;
  top: 3000px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/green.png);
  background-color: #0077b6;
}

div.bRes-39 .done11 {
  position: absolute;
  left: 396px;
  top: 3000px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/road-map_box-11.jpg);
}

div.bRes-40 {
  position: absolute;
  left: 840px;
  top: 3000px;
  width: 193px;
  height: 421px;
}

div.bRes-41 {
  position: absolute;
  left: 1033px;
  top: 3189px;
  width: 444px;
  height: 232px;
}

div.bRes-42 {
  position: absolute;
  left: 396px;
  top: 3284px;
  width: 444px;
  height: 107px;
}

div.bRes-43 {
  position: absolute;
  left: 59px;
  top: 3391px;
  width: 14px;
  height: 4370px;
}

div.bRes-44 .part12 {
  position: absolute;
  left: 73px;
  top: 3391px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/bRes_07.jpg);
}

div.bRes-44 .current12 {
  position: absolute;
  left: 73px;
  top: 3391px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/green.png);
  background-color: #0077b6;
}
div.bRes-44 .done12 {
  position: absolute;
  left: 73px;
  top: 3391px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/road-map_box-12.jpg);
}

div.bRes-45 {
  position: absolute;
  left: 517px;
  top: 3391px;
  width: 323px;
  height: 30px;
}

div.bRes-46 {
  position: absolute;
  left: 517px;
  top: 3421px;
  width: 300px;
  height: 349px;
}

div.bRes-47 .part13 {
  position: absolute;
  left: 817px;
  top: 3421px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/bRes_07.jpg);
}

div.bRes-47 .current13 {
  position: absolute;
  left: 817px;
  top: 3421px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/green.png);
  background-color: #0077b6;
}

div.bRes-47 .done13 {
  position: absolute;
  left: 817px;
  top: 3421px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/road-map_box-13.jpg);
}

div.bRes-48 {
  position: absolute;
  left: 1261px;
  top: 3421px;
  width: 216px;
  height: 737px;
}

div.bRes-49 {
  position: absolute;
  left: 73px;
  top: 3675px;
  width: 444px;
  height: 95px;
}

div.bRes-50 {
  position: absolute;
  left: 817px;
  top: 3705px;
  width: 444px;
  height: 65px;
}

div.bRes-51 {
  position: absolute;
  left: 73px;
  top: 3770px;
  width: 396px;
  height: 385px;
}

div.bRes-52 .part14 {
  position: absolute;
  left: 469px;
  top: 3770px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/bRes_07.jpg);
}
div.bRes-52 .current14 {
  position: absolute;
  left: 469px;
  top: 3770px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/green.png);
  background-color: #0077b6;
}
div.bRes-52 .done14 {
  position: absolute;
  left: 469px;
  top: 3770px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/road-map_box-14.jpg);
}

div.bRes-53 {
  position: absolute;
  left: 913px;
  top: 3770px;
  width: 348px;
  height: 388px;
}

div.bRes-54 {
  position: absolute;
  left: 469px;
  top: 4054px;
  width: 444px;
  height: 101px;
}

div.bRes-55 {
  position: absolute;
  left: 73px;
  top: 4155px;
  width: 44px;
  height: 508px;
}

div.bRes-56 .part15 {
  position: absolute;
  left: 117px;
  top: 4155px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/bRes_07.jpg);
}

div.bRes-56 .current15 {
  position: absolute;
  left: 117px;
  top: 4155px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/green.png);
  background-color: #0077b6;
}

div.bRes-56 .done15 {
  position: absolute;
  left: 117px;
  top: 4155px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/road-map_box-15.jpg);
}

div.bRes-57 {
  position: absolute;
  left: 561px;
  top: 4155px;
  width: 352px;
  height: 3px;
}

div.bRes-58 {
  position: absolute;
  left: 561px;
  top: 4158px;
  width: 281px;
  height: 974px;
}

div.bRes-59 .part16 {
  position: absolute;
  left: 842px;
  top: 4158px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/bRes_07.jpg);
}

div.bRes-59 .current16 {
  position: absolute;
  left: 842px;
  top: 4158px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/green.png);
  background-color: #0077b6;
}

div.bRes-59 .done16 {
  position: absolute;
  left: 842px;
  top: 4158px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/road-map_box-16.jpg);
}

div.bRes-60 {
  position: absolute;
  left: 1286px;
  top: 4158px;
  width: 191px;
  height: 395px;
}

div.bRes-61 {
  position: absolute;
  left: 117px;
  top: 4439px;
  width: 444px;
  height: 224px;
}

div.bRes-62 {
  position: absolute;
  left: 842px;
  top: 4442px;
  width: 444px;
  height: 111px;
}

div.bRes-63 {
  position: absolute;
  left: 842px;
  top: 4553px;
  width: 119px;
  height: 579px;
}

div.bRes-64 .part17 {
  position: absolute;
  left: 961px;
  top: 4553px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/bRes_07.jpg);
}

div.bRes-64 .current17 {
  position: absolute;
  left: 961px;
  top: 4553px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/green.png);
  background-color: #0077b6;
}

div.bRes-64 .done17 {
  position: absolute;
  left: 961px;
  top: 4553px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/road-map_box-17.jpg);
}

div.bRes-65 {
  position: absolute;
  left: 1405px;
  top: 4553px;
  width: 72px;
  height: 752px;
}

div.bRes-66 {
  position: absolute;
  left: 73px;
  top: 4663px;
  width: 29px;
  height: 1287px;
}

div.bRes-67 .part18 {
  position: absolute;
  left: 102px;
  top: 4663px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/bRes_07.jpg);
}
div.bRes-67 .current18 {
  position: absolute;
  left: 102px;
  top: 4663px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/green.png);
  background-color: #0077b6;
}

div.bRes-67 .done18 {
  position: absolute;
  left: 102px;
  top: 4663px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/road-map_box-18.jpg);
}

div.bRes-68 {
  position: absolute;
  left: 546px;
  top: 4663px;
  width: 15px;
  height: 469px;
}

div.bRes-69 {
  position: absolute;
  left: 961px;
  top: 4837px;
  width: 444px;
  height: 468px;
}

div.bRes-70 {
  position: absolute;
  left: 102px;
  top: 4947px;
  width: 444px;
  height: 185px;
}

div.bRes-71 {
  position: absolute;
  left: 102px;
  top: 5132px;
  width: 318px;
  height: 818px;
}

div.bRes-72 .part19 {
  position: absolute;
  left: 420px;
  top: 5132px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/bRes_07.jpg);
}

div.bRes-72 .current19 {
  position: absolute;
  left: 420px;
  top: 5132px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/green.png);
  background-color: #0077b6;
}

div.bRes-72 .done19 {
  position: absolute;
  left: 420px;
  top: 5132px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/road-map_box-19.jpg);
}

div.bRes-73 {
  position: absolute;
  left: 864px;
  top: 5132px;
  width: 97px;
  height: 440px;
}

div.bRes-74 {
  position: absolute;
  left: 961px;
  top: 5305px;
  width: 39px;
  height: 769px;
}

div.bRes-75 .part20 {
  position: absolute;
  left: 1000px;
  top: 5305px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/bRes_07.jpg);
}

div.bRes-75 .current20 {
  position: absolute;
  left: 1000px;
  top: 5305px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/green.png);
  background-color: #0077b6;
}

div.bRes-75 .done20 {
  position: absolute;
  left: 1000px;
  top: 5305px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/road-map_box-20.jpg);
}

div.bRes-76 {
  position: absolute;
  left: 1444px;
  top: 5305px;
  width: 33px;
  height: 2456px;
}

div.bRes-77 {
  position: absolute;
  left: 420px;
  top: 5416px;
  width: 444px;
  height: 156px;
}

div.bRes-78 {
  position: absolute;
  left: 420px;
  top: 5572px;
  width: 71px;
  height: 378px;
}

div.bRes-79 .part21 {
  position: absolute;
  left: 491px;
  top: 5572px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/bRes_07.jpg);
}
div.bRes-79 .current21 {
  position: absolute;
  left: 491px;
  top: 5572px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/green.png);
  background-color: #0077b6;
}
div.bRes-79 .done21 {
  position: absolute;
  left: 491px;
  top: 5572px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/road-map_box-21.jpg);
}

div.bRes-80 {
  position: absolute;
  left: 935px;
  top: 5572px;
  width: 26px;
  height: 502px;
}

div.bRes-81 {
  position: absolute;
  left: 1000px;
  top: 5589px;
  width: 444px;
  height: 485px;
}

div.bRes-82 {
  position: absolute;
  left: 491px;
  top: 5856px;
  width: 444px;
  height: 94px;
}

div.bRes-83 {
  position: absolute;
  left: 73px;
  top: 5950px;
  width: 23px;
  height: 1811px;
}

div.bRes-84 .part22 {
  position: absolute;
  left: 96px;
  top: 5950px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/bRes_07.jpg);
}
div.bRes-84 .current22 {
  position: absolute;
  left: 96px;
  top: 5950px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/green.png);
  background-color: #0077b6;
}

div.bRes-84 .done22 {
  position: absolute;
  left: 96px;
  top: 5950px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/road-map_box-22.jpg);
}

div.bRes-85 {
  position: absolute;
  left: 540px;
  top: 5950px;
  width: 395px;
  height: 124px;
}

div.bRes-86 {
  position: absolute;
  left: 540px;
  top: 6074px;
  width: 324px;
  height: 827px;
}

div.bRes-87 .part23 {
  position: absolute;
  left: 864px;
  top: 6074px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/bRes_07.jpg);
}

div.bRes-87 .current23 {
  position: absolute;
  left: 864px;
  top: 6074px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/green.png);
  background-color: #0077b6;
}
div.bRes-87 .done23 {
  position: absolute;
  left: 864px;
  top: 6074px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/road-map_box-23.jpg);
}

div.bRes-88 {
  position: absolute;
  left: 1308px;
  top: 6074px;
  width: 136px;
  height: 1687px;
}

div.bRes-89 {
  position: absolute;
  left: 96px;
  top: 6234px;
  width: 444px;
  height: 205px;
}

div.bRes-90 {
  position: absolute;
  left: 864px;
  top: 6358px;
  width: 444px;
  height: 543px;
}

div.bRes-91 .part24 {
  position: absolute;
  left: 96px;
  top: 6439px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/bRes_07.jpg);
}

div.bRes-91 .current24 {
  position: absolute;
  left: 96px;
  top: 6439px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/green.png);
  background-color: #0077b6;
}

div.bRes-91 .done24 {
  position: absolute;
  left: 96px;
  top: 6439px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/road-map_box-24.jpg);
}

div.bRes-92 {
  position: absolute;
  left: 96px;
  top: 6723px;
  width: 444px;
  height: 1038px;
}

div.bRes-93 {
  position: absolute;
  left: 540px;
  top: 6901px;
  width: 272px;
  height: 576px;
}

div.bRes-94 .part25 {
  position: absolute;
  left: 812px;
  top: 6901px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/bRes_07.jpg);
}

div.bRes-94 .current25 {
  position: absolute;
  left: 812px;
  top: 6901px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/green.png);
  background-color: #0077b6;
}

div.bRes-94 .done25 {
  position: absolute;
  left: 812px;
  top: 6901px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/road-map_box-25.jpg);
}
div.bRes-95 {
  position: absolute;
  left: 1256px;
  top: 6901px;
  width: 52px;
  height: 860px;
}

div.bRes-96 {
  position: absolute;
  left: 812px;
  top: 7185px;
  width: 444px;
  height: 292px;
}

div.bRes-97 {
  position: absolute;
  left: 540px;
  top: 7477px;
  width: 13px;
  height: 284px;
}

div.bRes-98 .part26 {
  position: absolute;
  left: 553px;
  top: 7477px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/bRes_07.jpg);
}
div.bRes-98 .current26 {
  position: absolute;
  left: 553px;
  top: 7477px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/green.png);
  background-color: #0077b6;
}
div.bRes-98 .done26 {
  position: absolute;
  left: 553px;
  top: 7477px;
  width: 444px;
  height: 284px;
  background-image: url(/src/images/road-map_box-26.jpg);
}

div.bRes-99 {
  position: absolute;
  left: 997px;
  top: 7477px;
  width: 259px;
  height: 284px;
}

div.bRes-100 {
  position: absolute;
  left: 0px;
  top: 7761px;
  width: 1519px;
  height: 714px;
}

.time {
  display: flex;
  flex-direction: column;
  position: sticky;
  bottom: 0;
  background-color: #ef233c;
  height: auto;
  width: 100%;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.time__first {
  color: #fff;
}
.time__first,
.time__second {
  margin-bottom: 0 !important;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}
a.bandeau {
  color: inherit;
  text-decoration: none;
}

.finished {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  background-color: rgba(255, 255, 255, 0.664);
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  z-index: 9000;
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;
}

.finished__text {
  font-size: 2.5rem;
  text-align: center;
}
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700&display=swap");

.count {
  display: inline;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: white !important;
  margin-bottom: 0 !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
}

/*Medium devices (tablets, 768px and up)*/
@media (max-width: 1440px) {
  .izy_rehetra {
    zoom: 0.95;
    margin: 0;
  }
  div {
    resize: both;
  }
  div.Table_01 {
    zoom: 100%;
  }
  .time {
    height: auto;
    font-size: 10px;
    flex-direction: column;
  }

  .time__first,
  .time__second {
    margin-bottom: 0 !important;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
  }
  .count {
    display: block;
  }
  .finished__text {
    text-align: center;
    font-size: 1.5rem;
  }
}

/*Medium devices (tablets, 768px and up)*/
@media (max-width: 868px) {
  .izy_rehetra {
    zoom: 0.24;
    margin: 0;
  }
  div {
    resize: both;
  }
  div.Table_01 {
    zoom: 100%;
  }
  #tsparticles {
    width: 100%;
    height: 100%;
    position: fixed;
    margin: 0;
    top: 0;
    left: 0;
  }
}

.mobile_view {
  min-height: 100vh;
  z-index: -1;
}

.rectangle {
  width: 80vw;
  height: 20vh;
  background-color: green;
  border-radius: 30px;
  z-index: 2;
}
