.ixy{
    display: flex;
    align-items: center;
    justify-content: center;
	background-color: #fff;
	margin-left:auto ;
	margin-right: auto;
}

#tsparticles {
	width: 100%;
	height: 100%;
	position: fixed;
	margin: 0;
	top: 0;
	left: 0;
  }
  
  #tsparticles canvas {
	display: block;
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
	opacity: 1;
	-webkit-transition: opacity 0.8s ease, -webkit-transform 1.4s ease;
	transition: opacity 0.8s ease, transform 1.4s ease;
  }
  
  canvas {
	display: block;
	position: fixed;
	z-index: -1;
  }
div.Table_02 {
	position:relative;
	left:0px;
	top:0px;
	width:374px;
	height:8190px;
}

div.mob-01 {
	position:absolute;
	left:0px;
	top:0px;
	width:374px;
	height:277px;
}

div.mob-02 {
	position:absolute;
	left:0px;
	top:277px;
	width:27px;
	height:7655px;
}

div.mob-03 .done1 {
	position:absolute;
	left:27px;
	top:277px;
	width:318px;
    height:201px;
    background-size: cover;
    background-image: url(/src/images/road-map_box-1.jpg);
}

div.mob-04 {
	position:absolute;
	left:345px;
	top:277px;
	width:29px;
	height:322px;
}

div.mob-05 {
	position:absolute;
	left:27px;
	top:478px;
	width:318px;
	height:121px;
}

div.mob-06 {
	position:absolute;
	left:27px;
	top:599px;
	width:2px;
	height:901px;
}

div.mob-07 .done2 {
	position:absolute;
	left:29px;
	top:599px;
	width:318px;
    height:201px;
    background-size: cover;
    background-image: url(/src/images/road-map_box-3.jpg);
}

div.mob-08 {
	position:absolute;
	left:347px;
	top:599px;
	width:27px;
	height:7333px;
}

div.mob-09 {
	position:absolute;
	left:29px;
	top:800px;
	width:318px;
	height:39px;
}

div.mob-10 .done3 {
	position:absolute;
	left:29px;
	top:839px;
	width:318px;
    height:201px;
    background-size: cover;
    background-image: url(/src/images/road-map_box-2.jpg);
}

div.mob-11 {
	position:absolute;
	left:29px;
	top:1040px;
	width:318px;
	height:95px;
}

div.mob-12 .done4{
	position:absolute;
	left:29px;
	top:1135px;
	width:317px;
    height:201px;
    background-size: cover;
    background-image: url(/src/images/road-map_box-4.jpg);
}

div.mob-13 {
	position:absolute;
	left:346px;
	top:1135px;
	width:1px;
	height:365px;
}

div.mob-14 {
	position:absolute;
	left:29px;
	top:1336px;
	width:317px;
	height:164px;
}

div.mob-15 {
	position:absolute;
	left:27px;
	top:1500px;
	width:1px;
	height:6690px;
}

div.mob-16 .done5 {
	position:absolute;
	left:28px;
	top:1500px;
	width:319px;
    height:202px;
    background-size: cover;
    background-image: url(/src/images/road-map_box-5.jpg);
}

div.mob-17 {
	position:absolute;
	left:28px;
	top:1702px;
	width:319px;
	height:60px;
}

div.mob-18 {
	position:absolute;
	left:28px;
	top:1762px;
	width:1px;
	height:6428px;
}

div.mob-19 .done6 {
	position:absolute;
	left:29px;
	top:1762px;
	width:317px;
    height:201px;
    background-size: cover;
    background-image: url(/src/images/road-map_box-7.jpg);
}

div.mob-20 {
	position:absolute;
	left:346px;
	top:1762px;
	width:1px;
	height:285px;
}

div.mob-21 {
	position:absolute;
	left:29px;
	top:1963px;
	width:317px;
	height:84px;
}

div.mob-22 .done7 {
	position:absolute;
	left:29px;
	top:2047px;
	width:318px;
    height:202px;
    background-size: cover;
    background-image: url(/src/images/road-map_box-6.jpg);
}

div.mob-23 {
	position:absolute;
	left:29px;
	top:2249px;
	width:318px;
	height:39px;
}

div.mob-24 .done8 {
	position:absolute;
	left:29px;
	top:2288px;
	width:318px;
    height:202px;
    background-size: cover;
    background-image: url(/src/images/road-map_box-8.jpg);
}

div.mob-25 {
	position:absolute;
	left:29px;
	top:2490px;
	width:318px;
	height:211px;
}

div.mob-26 .done9 {
	position:absolute;
	left:29px;
	top:2701px;
	width:318px;
    height:202px;
    background-size: cover;
    background-image: url(/src/images/road-map_box-9.jpg);
}

div.mob-27 {
	position:absolute;
	left:29px;
	top:2903px;
	width:318px;
	height:60px;
}

div.mob-28 .done10 {
	position:absolute;
	left:29px;
	top:2963px;
	width:318px;
    height:201px;
    background-size: cover;
    background-image: url(/src/images/road-map_box-10.jpg);
}

div.mob-29 {
	position:absolute;
	left:29px;
	top:3164px;
	width:318px;
	height:122px;
}

div.mob-30 .done11 {
	position:absolute;
	left:29px;
	top:3286px;
	width:318px;
    height:202px;
    background-size: cover;
    background-image: url(/src/images/road-map_box-11.jpg);
}

div.mob-31 {
	position:absolute;
	left:29px;
	top:3488px;
	width:318px;
	height:40px;
}

div.mob-32 .done12 {
	position:absolute;
	left:29px;
	top:3528px;
	width:317px;
    height:201px;
    background-size: cover;
    background-image: url(/src/images/road-map_box-12.jpg);
}

div.mob-33 {
	position:absolute;
	left:346px;
	top:3528px;
	width:1px;
	height:295px;
}

div.mob-34 {
	position:absolute;
	left:29px;
	top:3729px;
	width:317px;
	height:94px;
}

div.mob-35 .done13 {
	position:absolute;
	left:29px;
	top:3823px;
	width:318px;
    height:202px;
    background-size: cover;
    background-image: url(/src/images/road-map_box-13.jpg);
}

div.mob-36 {
	position:absolute;
	left:29px;
	top:4025px;
	width:318px;
	height:164px;
}

div.mob-37 .done14 {
	position:absolute;
	left:29px;
	top:4189px;
	width:318px;
    height:202px;
    background-size: cover;
    background-image: url(/src/images/road-map_box-14.jpg);
}

div.mob-38 {
	position:absolute;
	left:29px;
	top:4391px;
	width:318px;
	height:60px;
}

div.mob-39 .done15 {
	position:absolute;
	left:29px;
	top:4451px;
	width:318px;
    height:201px;
    background-size: cover;
    background-image: url(/src/images/road-map_box-15.jpg);
}

div.mob-40 {
	position:absolute;
	left:29px;
	top:4652px;
	width:318px;
	height:84px;
}

div.mob-41 .done16 {
	position:absolute;
	left:29px;
	top:4736px;
	width:318px;
    height:201px;
    background-size: cover;
    background-image: url(/src/images/road-map_box-16.jpg);
}

div.mob-42 {
	position:absolute;
	left:29px;
	top:4937px;
	width:318px;
	height:40px;
}

div.mob-43 .done17 {
	position:absolute;
	left:29px;
	top:4977px;
	width:318px;
    height:201px;
    background-size: cover;
    background-image: url(/src/images/road-map_box-17.jpg);
}

div.mob-44 {
	position:absolute;
	left:29px;
	top:5178px;
	width:318px;
	height:213px;
}

div.mob-45 .done18 {
	position:absolute;
	left:29px;
	top:5391px;
	width:318px;
    height:202px;
    background-size: cover;
    background-image: url(/src/images/road-map_box-18.jpg);
}

div.mob-46 {
	position:absolute;
	left:29px;
	top:5593px;
	width:318px;
	height:58px;
}

div.mob-47 .done19 {
	position:absolute;
	left:29px;
	top:5651px;
	width:318px;
    height:201px;
    background-size: cover;
    background-image: url(/src/images/road-map_box-19.jpg);
}

div.mob-48 {
	position:absolute;
	left:29px;
	top:5852px;
	width:318px;
	height:32px;
}

div.mob-49 .done20{
	position:absolute;
	left:29px;
	top:5884px;
	width:318px;
    height:202px;
    background-size: cover;
    background-image: url(/src/images/road-map_box-20.jpg);
}

div.mob-50 {
	position:absolute;
	left:29px;
	top:6086px;
	width:318px;
	height:60px;
}

div.mob-51 .done21 {
	position:absolute;
	left:29px;
	top:6146px;
	width:318px;
    height:201px;
    background-size: cover;
    background-image: url(/src/images/road-map_box-21.jpg);
}

div.mob-52 {
	position:absolute;
	left:29px;
	top:6347px;
	width:318px;
	height:123px;
}

div.mob-53 .done22 {
	position:absolute;
	left:29px;
	top:6470px;
	width:318px;
    height:201px;
    background-size: cover;
    background-image: url(/src/images/road-map_box-22.jpg);
}

div.mob-54 {
	position:absolute;
	left:29px;
	top:6671px;
	width:318px;
	height:34px;
}

div.mob-55 .done23 {
	position:absolute;
	left:29px;
	top:6705px;
	width:318px;
    height:201px;
    background-size: cover;
    background-image: url(/src/images/road-map_box-23.jpg);
}

div.mob-56 {
	position:absolute;
	left:29px;
	top:6906px;
	width:318px;
	height:165px;
}

div.mob-57 .done24 {
	position:absolute;
	left:29px;
	top:7071px;
	width:318px;
    height:201px;
    background-size: cover;
    background-image: url(/src/images/road-map_box-24.jpg);
}

div.mob-58 {
	position:absolute;
	left:29px;
	top:7272px;
	width:318px;
	height:58px;
}

div.mob-59 .done25 {
	position:absolute;
	left:29px;
	top:7330px;
	width:318px;
    height:201px;
    background-size: cover;
    background-image: url(/src/images/road-map_box-25.jpg);
}

div.mob-60 {
	position:absolute;
	left:29px;
	top:7531px;
	width:318px;
	height:200px;
}

div.mob-61 .done26 {
	position:absolute;
	left:29px;
	top:7731px;
	width:318px;
    height:201px;
    background-size: cover;
    background-image: url(/src/images/road-map_box-26.jpg);
}

div.mob-62 {
	position:absolute;
	left:0px;
	top:7932px;
	width:374px;
	height:258px;

}

.btnStyle{
    position:absolute;
    margin-left:auto;
    margin-right:auto;
    background-color: #1b8ece;
    color: white;
    border-radius:15px;
    border:none;
    font-weight: 600;
    width:318px;
    height:30px;
    left:27px;
}

.btn5{
	top:520px;	
}

.btn14 {
	top:1399px;
}
.btn25{
    top:2590px;
}
.btn29{
    top:3214px;
}
.btn36{
    top:4050px;
}

.btn44{
    top:5278px;
    
}
.btn56{
    top:6950px;
}
.btn60{
    top:7599px;
}
.btn62{
    top:8100px;
}

.textVoici{
	position:relative;
	display: flex;
	justify-content: center;
align-items: center;
	margin-left: auto;
	margin-right: auto;
	top:240px;
	width:auto;
	font-size:9px;
	font-weight: 600;
	color: #b1b1b1;
	text-align: center;
}