.btn_try{
    position: absolute !important;
    top:40%;
    width: 506px;
    height: 73px;
    z-index: 10 !important;
    background-color: #1b8ece;
    color: white;
    font-size:20px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    border-radius: 15px!important;
    overflow: visible;

    .btn_33{
        position:absolute;
        left:459px;
        top:2761px;
        z-index: 10!important;
      }
  }